import styled from 'styled-components';
import { Container as MuiContainer } from '@mui/material';

export const Container = styled(MuiContainer)`
  margin-top: 12rem;
  && {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  /* justify-content:center; */
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-top: 5rem;
`;
