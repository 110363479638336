import React from 'react';
// import PropTypes from 'prop-types'
// import HeaderWithSidebar from '../HeaderWithSidebar'
import { useRouter } from 'next/router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Head from 'next/head';
import { Container, LogoContainer, Content } from './style';
import Link from '../../atoms/Link';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import { useTranslation } from '../../../config/i18n';

const Logo = '/images/mubazar-logo-BETA-RED.png';

// import Button

const ErrorTemplate = ({ children, title }) => {
  const { t } = useTranslation('common')
  const router = useRouter();

  const handleBackClick = () => {
    router.back();
  };

  return (
    <>
      <Head>
        <title>Mubazar - {title}</title>
      </Head>
      <Container>
        <Content>{children}</Content>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          color="primary"
          onClick={handleBackClick}
        >
          {t('common:back')}
        </Button>
        <LogoContainer>
          <Link href="/" color="inherit">
            <Image bottom="0.3125rem" width="15rem" src={Logo} />
          </Link>
        </LogoContainer>
      </Container>
    </>
  );
};

ErrorTemplate.propTypes = {};

export default ErrorTemplate;
